import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';
import ES6Promise from "es6-promise";
ES6Promise.polyfill();
import $ from "jquery";
//= require popper.js/dist/umd/popper.js
//= require bootstrap/dist/js/bootstrap.js
import axios from 'axios';
import toastr from 'toastr';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';
import lazyLoad from '../customs/lazy_load';
import '../customs/sweet-alert-confirm';


Rails.start();
ActiveStorage.start();

axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken();
window.axios = axios;
window.toastr = toastr;
window.$ = $;
var current_slide = 1;
var slide_timeout = null;
var loginNeed = false;
window.axios.interceptors.response.use(function (response) {
  // Do something with response data
  console.log('global axios success');
  return response;
}, function (error) {
  // Do something with response error
  console.log(['global axios error', error, error.request.responseURL.indexOf('users.json')]);
  if(error.response.status == 401 && error.request.responseURL.indexOf('users.json') == -1) {
    if(error.response.data && error.response.data.error && !loginNeed) {
      toastr.error(error.response.data.error);
      loginNeed = true;
      setTimeout(function(){
        loginNeed = false;
      }, 1000);
    }
    $('#loginModal').modal('show');
    $('.login-tabs, .login-modal__nav a').removeClass('active');
    $('.loginModalTab, .loginModal').addClass('active');
  }
  if(error.response.status == 422 && error.response.data.type == 'reload') {
    window.location.reload();
  }
   return Promise.reject(error);
});

window.toastr.options.positionClass = 'toast-top-center';

if (!Array.prototype.last){
    Array.prototype.last = function(){
        return this[this.length - 1];
    };
};

window.move_slider = function(move_to = null) {
  if(move_to) {
    current_slide = move_to;
  }
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if(w < 992) {
    return;
  }
  clearTimeout(slide_timeout);
  $('.slider__dot').removeClass('slider__dot--active');
  $('.slider__dot[data-slide='+current_slide+']').addClass('slider__dot--active');
  $('.slider__slides__inner').css('left', ((1-current_slide)*100)+'%');
  // var count = $('.slider__dot').length;
  // slide_timeout = setTimeout(function(){
  //   if(current_slide == count) {
  //     current_slide = 1;
  //   } else {
  //     current_slide++;
  //   }
  //   move_slider();
  // }, 15000);
}

function manageHeader() {
  // if(window.scrollY > 0) {
  //   $('.header').addClass('header--fixed');
  // } else {
  //   $('.header').removeClass('header--fixed');
  // }
  // if(document.getElementById("slider_place_an_ad").getBoundingClientRect().bottom < 82) {
  //   $('.header').addClass('header--withbtn');
  // } else {
  //   $('.header').removeClass('header--withbtn');
  // }
}

function displayOnChecked(checkbox) {
  // var $checkbox = $(checkbox);
  if(checkbox.checked == true) {
    $(checkbox.dataset.target).removeClass('d-none');
  } else {
    $(checkbox.dataset.target).addClass('d-none');
  }
}

$(function() {
  $('.dropdown-toggle').dropdown();
  // $('[data-toggle=collapse]').collapse();

  $(document).on('click', '[data-toggle=mycollapse]', function(){
    $(this).toggleClass('is-active');
    $($(this).data('target')).toggleClass('show');
  });

  $(document).on('click', '[data-toggle=class]', function(e){
    e.preventDefault();
    var classToToggle = $(this).data('class');
    $($(this).data('target')).toggleClass(classToToggle);
  });

  $(document).on('click', '[data-toggle=loginmodal]', function(e) {
    e.preventDefault();
    var attr = $(this).attr('href');
    if(attr == '#login') {
      $('#loginModal').modal('show');
      $('.login-tabs, .login-modal__nav a').removeClass('active');
      $('.loginModalTab, .loginModal').addClass('active');
    }
    if(attr == '#sign_up') {
      $('#loginModal').modal('show');
      $('.login-tabs, .login-modal__nav a').removeClass('active');
      $('.signUpModalTab, .signUpModal').addClass('active');
    }
  });

  $(document).on('click', '[data-toggle=tabs]', function(e){
    e.preventDefault();
    var classToToggle = $(this).data('class');
    var targetToActive = $(this).data('target');
    var tabs = $(this).data('tabs');
    $(tabs).removeClass(classToToggle);
    $(targetToActive).addClass(classToToggle);
  });

  $(document).on('click', '.cookies .cookies__accept', function(e){
    e.preventDefault();
    $('.cookies').addClass('cookies--hidden');
    axios.post('/cookies');
  });

  $(document).on('click', '.slider__dot', function(e){
    e.preventDefault();
    current_slide = this.dataset.slide;
    move_slider();
  });

  $(document).on('submit', '#loginForm', function(e) {
    e.preventDefault();
    var formData = $(this).serialize();
    console.log($(this).attr('action'));
    axios.post($(this).attr('action')+'.json', formData)
    .then(function (response) {
      // console.log(response);
      window.location.href = response.data.url;
    })
    .catch(function (error, response) {
      if(error.response.status == 422 && error.response.data.error) {
        toastr.error(error.response.data.error);
      }
    });
  });

  $(document).on('submit', '#passwordForm', function(e) {
    e.preventDefault();
    var formData = $(this).serialize();
    console.log($(this).attr('action'));
    axios.post($(this).attr('action')+'.json', formData)
    .then(function (response) {
      window.location.reload();
    })
    .catch(function (error, response) {
      if(error.response.status == 422) {
        console.log(['toast', error.response.data]);
        for (var i = 0; i < error.response.data.errors.length; i++) {
          toastr.error(error.response.data.errors[i]);
        }
      }
    });
  });

  $(document).on('mousedown', function(e) {
    var $select = $(e.target).parents('div.select');
    if($select.length > 0) {
      var $selectControl = $(e.target).parents('.select__control');
      if($selectControl.length > 0) {
        var isOpen = $select.hasClass('select--active');
        if(isOpen && e.target.tagName.toLowerCase() == 'input') {
          return;
        }
        $('div.select.select--active').removeClass('select--active');
        if(!isOpen) {
          // console.log(['Open!', $select.find('.select__control input').length]);
          $select.addClass('select--active');
          setTimeout(function(){
            $select.find('.select__control input').focus();
          }, 1);
        }
      }
    } else {
      $('div.select.select--active').removeClass('select--active');
    }
    var $moreOptions = $(e.target).parents('.search__more-options');
    if($moreOptions.length == 0 && !$(e.target).hasClass('search__more-options') && !$(e.target).hasClass('search__more-options-link')) {
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if(w > 991) {
        $('.search__more-options--active').removeClass('search__more-options--active');
      }
    }
  });

  $(document).on('focus', '.form-group--material .form-control' , function(e) {
    $(this).parent('.form-group--material').addClass('form-group--focused');
  });
  $(document).on('focusout', '.form-group--material .form-control' , function(e) {
    if($(this).val().length > 0) {
      $(this).parent('.form-group--material').addClass('form-group--value');
    } else {
      $(this).parent('.form-group--material').removeClass('form-group--value');
    }
    $(this).parent('.form-group--material').removeClass('form-group--focused');
  });
  $(document).on('click', '#toggleChat', function(e) {
      window.chat.$children[0].toggleChat();
  });
  $(document).on('click', '#toggleMap', function(e) {
      window.catalog.$children[0].$children[0].toggleMap();
  });


  $(document).on('change', '#selectAll', function(e) {
    if($(this).is(':checked')) {
      $('.list-checkboxes').prop('checked', true);
    } else {
      $('.list-checkboxes').prop('checked', false);
    }
  });

  $(document).on('submit', '#checkedAllForm', function(e){
    e.preventDefault();
    var values = [];
    $('.list-checkboxes').each(function(){
      if($(this).is(':checked')) {
        values.push($(this).prop('value'));
      }
    });
    var params = {};
    $('.fields').each(function(){
      params[$(this).prop('name')] = $(this).val();
    });
    params.list = values;
    // console.log(['selected', params]);
    axios.post($(this).prop('action')+'.json', params)
    .then(function (response) {
      window.location.reload();
    })
    .catch(function (error, response) {
      if(error.response.status == 401) {
        toastr.error(error.response.data.error);
      }
      if(error.response.status == 422 && error.response.data.error) {
        toastr.error(error.response.data.error);
      }
    });
  });



  window.addEventListener('scroll', function(e) {
    manageHeader();
  });
  manageHeader();

  // $(document).on('submit', '#signUpForm', function(e) {
  //   e.preventDefault();
  //   var formData = $(this).serialize();
  //   console.log($(this).attr('action'));
  //   axios.post($(this).attr('action')+'.json', formData)
  //   .then(function (response) {
  //     console.log(response);
  //   })
  //   .catch(function (error, response) {
  //     if(error.response.status == 401) {
  //       toastr.error(error.response.data.error);
  //     }
  //   });
  // });

  lazyLoad();
  if(window.need_login) {
    $('#loginModal').modal('show');
    $('.login-tabs, .login-modal__nav a').removeClass('active');
    $('.loginModalTab, .loginModal').addClass('active');
  }
  // if(window.location.hash == '#sign_up') {
  //   $('#loginModal').modal('show');
  //   $('.login-tabs, .login-modal__nav a').removeClass('active');
  //   $('.signUpModalTab, .signUpModal').addClass('active');
  // }
  // $('#loginModal').on('hidden.bs.modal', function(){
  //   window.location.hash = '';
  //   history.pushState(null, null, ' ');
  // });
  if(document.getElementById('confirmationModal')) {
    $('#confirmationModal').modal('show');
  }
  move_slider();

  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  axios.post('/browser_info', {w: w, h: h});

  $('[data-toggle="tooltip"]').tooltip();

  if(window.success_message) {
    toastr.success(window.success_message);
  }

  if(window.error_message) {
    toastr.error(window.error_message);
  }

  $(document).on('change', '.display-on-checked', function(){
    displayOnChecked(this);
  })

  $('.display-on-checked').each(function(){
    displayOnChecked(this);
  });

  $(document).on('change', '.select-menu', function(){
    window.location.href = $(this).val();
  });

  window.onerror = function(msg, url, lineNo, columnNo, error) {
    var error_hash, req;
    error_hash = {
      message: msg,
      url: url,
      line_no: lineNo,
      column_no: columnNo,
      error: error,
      browser: navigator.userAgent,
      window_height: window.innerHeight,
      window_width: window.innerWidth
    };
    req = $.post('/js_errors.json', error_hash);
    req.success(function(data) {});
    return false;
  };

});

setInterval(function(){
  window.axios.get('/keep_loggedin');
}, 60*1000);
